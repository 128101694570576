<template>
  <b-sidebar
    id="edit-group-sidebar"
    :visible="isEditGroupSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => {
      $emit('update:is-edit-group-sidebar-active', val)
      resetGroupData()
    } "
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Edit Group
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Group Name -->
          <validation-provider
            #default="validationContext"
            name="Group Name"
            rules="required"
          >
            <b-form-group
              label="Set Group Name"
              label-for="group-name"
            >
              <b-form-input
                id="group-name"
                v-model="groupData.groupName"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Group"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Update
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import store from '@/store'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isEditGroupSidebarActive',
    event: 'update:is-edit-group-sidebar-active',
  },
  props: {
    isEditGroupSidebarActive: {
      type: Boolean,
      required: true,
    },
    currentGroup: {
      type: Object,
      required: true,
    },

  },
  data() {
    return {
      required,
      alphaNum,
      email,
    }
  },
  setup(props, { emit }) {
    /* const blankGroupData = {
      groupName: ''
    } */
    const originalGroupName = ref(props.currentGroup.groupName)
    const groupData = ref(JSON.parse(JSON.stringify(props.currentGroup)))
    const resetGroupData = () => {
      originalGroupName.value = props.currentGroup.groupName
      groupData.value = JSON.parse(JSON.stringify(props.currentGroup))
    }

    const onSubmit = () => {
      store.dispatch('app-user/editGroup', { groupData: groupData.value, originalGroupName: originalGroupName.value })
        .then(() => {
          emit('refetch-data')
          emit('update:is-edit-group-sidebar-active', false)
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetGroupData)

    return {
      groupData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
      resetGroupData,
      originalGroupName,
    }
  },
}
</script>
