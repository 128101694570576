<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>
        Import User Spreadsheet
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-file
              v-model="file"
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
            />
            <b-button
              variant="primary"
              @click="submitFile()"
            >
              <span class="text-nowrap">Upload</span>
            </b-button>
          </div>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-button
              variant="outline-primary"
              style="margin-right: 1rem;"
              target="_blank"
              href="https://aequip-public.s3.eu-west-2.amazonaws.com/upload_csv_instructions.pdf"
            >
              <span class="text-nowrap">View Instructions</span>
            </b-button>
            <b-button
              variant="outline-primary"
              href="https://aequip-public.s3.eu-west-2.amazonaws.com/user_upload_template.csv"
            >
              <span class="text-nowrap">Download Template</span>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol, BFormFile, BButton,
} from 'bootstrap-vue'
import store from '@/store'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BFormFile,
    BButton,
  },
  data() {
    return {
      file: '',
    }
  },
  methods: {
    // success
    success() {
      this.$swal({
        title: 'File Uploaded!',
        text: 'Thank you for uploading, we will review your file and process it shortly!',
        icon: 'success',
        allowOutsideClick: true,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    downloadCSV() {
      fetch('https://aequip-public.s3.eu-west-2.amazonaws.com/user_upload_template.xlsx')
        .then(response => response.blob())
        .then(data => {
          const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = 'user_upload_template.xlsx'
          link.click()
        })
        .catch(e => {
          console.log(e)
        })
    },
    // error
    error() {
      this.$swal({
        title: 'Upload Error!',
        text: ' Oops! Looks like something didnt go to plan, Sorry about that. Please try again, or contact Support',
        icon: 'error',
        allowOutsideClick: true,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    clearFiles() {
      this.file = ''
    },
    submitFile() {
      const formData = new FormData()
      formData.append('file', this.file)
      store.dispatch('app-user/uploadUserFile', formData)
        .then(() => {
          console.log('SUCCESS!!')
          this.$emit('refetch-data')

          this.clearFiles()
          this.success()
        })
        .catch(() => {
          console.log('FAILURE!!')
          this.error()
        })
    },
  },
}
</script>
