<template>

  <div>

    <user-list-add-new
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      :role-options="roleOptions.roles"
      @refetch-data="refetchData"
    />

    <group-list-add-new
      :is-add-new-group-sidebar-active.sync="isAddNewGroupSidebarActive"
      @refetch-data="refetchGroupData"
    />

    <group-list-edit
      :is-edit-group-sidebar-active.sync="isEditGroupSidebarActive"
      :current-group="currentGroup"
      @refetch-data="refetchGroupData"
    />

    <users-upload
      @refetch-data="refetchData"
    />

    <!-- Filters
    <users-list-filters
      :role-filter.sync="roleFilter"
      :status-filter.sync="statusFilter"
      :role-options="roleOptions"
      :status-options="statusOptions"
    /> -->
    <b-overlay
      :show="showUsersLoader"
      rounded="sm"
      spinner-variant="primary"
      spinner-type="grow"
    >
      <!-- Table Container Card -->
      <b-card
        no-body
      >
        <div class="m-2">

          <!-- Table Top -->
          <b-row>

            <!-- Per Page -->
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <h5>
                Users
              </h5>
            <!-- <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label> -->
            </b-col>

            <!-- Search -->
            <b-col
              cols="12"
              md="6"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  placeholder="Search..."
                />
                <b-button
                  variant="primary"
                  @click="isAddNewUserSidebarActive = true"
                >
                  <span class="text-nowrap">Add User</span>
                </b-button>
              </div>
            </b-col>
          </b-row>

        </div>

        <b-table
          ref="refUserListTable"
          class="position-relative"
          :items="fetchUsers"
          responsive
          :fields="tableColumns"
          primary-key="userName"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
        >

          <!-- Column: User -->
          <template #cell(user)="data">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                  size="32"
                  :src="data.item.avatar"
                  :text="avatarText(data.item.fullName)"
                  :variant="`light-${resolveUserRoleVariant(data.item.role)}`"
                  :to="{ name: 'edit-user', params: { id: data.item.userName } }"
                />
              </template>
              <b-link
                :to="{ name: 'edit-user', params: { id: data.item.userName } }"
                class="font-weight-bold d-block text-nowrap"
              >
                {{ data.item.firstName }} {{ data.item.lastName }}
              </b-link>
            </b-media>
          </template>

          <!-- Column: Email -->
          <template #cell(email)="data">
            <div class="text-nowrap">
              <span class="align-text-top">{{ data.item.userName }}</span>
            </div>
          </template>

          <!-- Column: Role -->
          <template #cell(role)="data">
            <div class="text-nowrap">
              <feather-icon
                :icon="resolveUserRoleIcon(data.item.role)"
                size="18"
                class="mr-50"
                :class="`text-${resolveUserRoleVariant(data.item.role)}`"
              />
              <span class="align-text-top text-capitalize">{{ resolveUserRole(data.item.role) }}</span>
            </div>
          </template>

          <!-- Column: Status -->
          <template #cell(status)="data">
            <b-badge
              pill
              :variant="`light-${resolveUserStatusVariant(data.item.confirmedStatus, data.item.enabled, data.item.role)}`"
              class="text-capitalize"
            >
              {{ resolveUserStatusText(data.item.confirmedStatus, data.item.enabled) }}
            </b-badge>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >

              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>

              <b-dropdown-item
                :to="{ name: 'edit-user', params: { id: data.item.userName } }"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Edit</span>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="data.item.enabled"
                @click="showSuspendConfirm(data.item)"
              >
                <feather-icon icon="AlertIcon" />
                <span class="align-middle ml-50">Suspend User</span>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="!data.item.enabled"
                @click="showResumeConfirm(data.item)"
              >
                <feather-icon icon="AlertIcon" />
                <span class="align-middle ml-50">Resume User</span>
              </b-dropdown-item>

              <b-dropdown-item
                @click="showDeleteConfirm(data.item)"
              >
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>

        </b-table>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">Showing <!--{{ dataMeta.from }} to {{ dataMeta.to }} of --> {{ dataMeta.of }} entries</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
            <!--
            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination> -->

            </b-col>

          </b-row>
        </div>
      </b-card>
    </b-overlay>

    <!-- Table Container Card GROUPS -->
    <b-overlay
      :show="showGroupsLoader"
      rounded="sm"
      spinner-variant="primary"
      spinner-type="grow"
    >
      <b-card
        no-body
        class="mb-0"
      >
        <div class="m-2">

          <!-- Table Top -->
          <b-row>
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <h5>
                User Groups
              </h5>
            <!-- <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label> -->
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-button
                  variant="primary"
                  @click="isAddNewGroupSidebarActive = true"
                >
                  <span class="text-nowrap ">Add Group</span>
                </b-button>
              </div>
            </b-col>
          </b-row>

        </div>

        <b-table
          ref="refGroupListTable"
          class="position-relative"
          :items="fetchGroups"
          responsive
          :fields="tableGroupColumns"
          primary-key="groupName"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
        >

          <!-- Column: GroupName -->
          <template #cell(group)="data">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                  size="32"
                  :variant="light-primary"
                  @click="showEditGroup(data.item)"
                >
                  <feather-icon
                    icon="UsersIcon"
                    size="20"
                    class="align-middle text-body"
                  />
                </b-avatar>
              </template>
              <b-link
                class="font-weight-bold d-block text-nowrap"
                @click="showEditGroup(data.item)"
              >
                {{ data.item.groupName }}
              </b-link>
            </b-media>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >

              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>

              <b-dropdown-item
                @click="showEditGroup(data.item)"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Edit</span>
              </b-dropdown-item>

              <b-dropdown-item
                @click="showDeleteGroupConfirm(data.item)"
              >
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>

        </b-table>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">Showing <!--{{ dataMeta.from }} to {{ dataMeta.to }} of--> {{ dataMeta.of }} entries</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
            <!--
            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination> -->

            </b-col>

          </b-row>
        </div>
      </b-card>
    </b-overlay>

  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BOverlay,
  // BPagination,
} from 'bootstrap-vue'
// import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
// import UsersListFilters from './UsersListFilters.vue'
import useUsersList from './useUsersList'
import userStoreModule from '../userStoreModule'
import UserListAddNew from './UserListAddNew.vue'
import GroupListAddNew from './GroupListAddNew.vue'
import GroupListEdit from './GroupListEdit.vue'

import UsersUpload from './UsersUpload.vue'

export default {
  components: {
    // UsersListFilters,
    UserListAddNew,
    GroupListAddNew,
    GroupListEdit,
    UsersUpload,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BOverlay,
    // BPagination,

    // vSelect,
  },
  data() {
    return {
    }
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const isAddNewUserSidebarActive = ref(false)
    const isAddNewGroupSidebarActive = ref(false)
    const isEditGroupSidebarActive = ref(false)
    const currentGroup = {
      groupName: '',
    }

    const roleOptions = {
      roles: [{ label: 'Loading', value: 'loading' }],
    }

    const statusOptions = [
      { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Suspended', value: 'inactive' },
    ]

    const {
      fetchUsers,
      fetchGroups,
      fetchRoles,
      tableColumns,
      tableGroupColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refGroupListTable,
      refetchData,
      refetchGroupData,

      showGroupsLoader,
      showUsersLoader,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserRole,
      resolveUserStatusVariant,
      resolveUserStatusText,

      // Extra Filters
      roleFilter,
      statusFilter,
    } = useUsersList()

    return {

      // Sidebar
      isAddNewUserSidebarActive,
      isAddNewGroupSidebarActive,
      isEditGroupSidebarActive,
      currentGroup,

      fetchUsers,
      fetchGroups,
      fetchRoles,
      tableColumns,
      tableGroupColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refGroupListTable,
      refetchData,
      refetchGroupData,

      showGroupsLoader,
      showUsersLoader,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserRole,
      resolveUserStatusVariant,
      resolveUserStatusText,

      statusOptions,
      roleOptions,
      // Extra Filters
      roleFilter,
      statusFilter,
    }
  },
  mounted() {
    this.fetchRoles(null, result => {
      this.roleOptions.roles = result.roles
    })
  },
  methods: {
    testEmit(value) {
      console.log(value)
    },
    showEditGroup(groupData) {
      this.currentGroup.groupName = groupData.groupName
      this.isEditGroupSidebarActive = true
      console.log(this)
    },
    showDeleteConfirm(userData) {
      this.$bvModal
        .msgBoxConfirm(`Please confirm that you want to delete ${userData.firstName} ${userData.lastName}?`, {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            store.dispatch('app-user/deleteUser', { id: userData.userName }, userData)
              .then(() => {
                this.refetchData()
              })
          }
        })
    },

    showDeleteGroupConfirm(groupData) {
      this.$bvModal
        .msgBoxConfirm(`Please confirm that you want to delete ${groupData.groupName}?`, {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            store.dispatch('app-user/deleteGroup', { id: groupData.groupName })
              .then(() => {
                this.refetchGroupData()
              })
          }
        })
    },

    showResumeConfirm(userData) {
      this.$bvModal
        .msgBoxConfirm(`Please confirm that you want to resume the account access for ${userData.firstName} ${userData.lastName}. `, {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            store.dispatch('app-user/enableUser', { id: userData.userName }, userData)
              .then(() => {
                this.refetchData()
              })
          }
        })
    },

    showSuspendConfirm(userData) {
      this.$bvModal
        .msgBoxConfirm(`Please confirm that you want to suspend the account access for ${userData.firstName} ${userData.lastName}.`, {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            store.dispatch('app-user/disableUser', { id: userData.userName }, userData)
              .then(() => {
                this.refetchData()
              })
          }
        })
    },
  },
}
</script>
<!--
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style> -->
